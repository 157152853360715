import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I have been working on updating the `}<a parentName="p" {...{
        "href": "https://github.com/opsdroid/opsdroid-web"
      }}>{`web client`}</a>{` for `}<a parentName="p" {...{
        "href": "https://opsdroid.dev"
      }}>{`opsdroid`}</a>{`. This new version comes with a new UI and some nice features, such as a theme selector, allowing you to set your username and profile picture.`}</p>
    <p>{`The problem with the web client is that I didn't want to run a backend or have opsdroid save the user's avatar. Ideally, a user would be able to upload their picture from the client settings, and when a user sends a message to opsdroid, the image should be the one that was uploaded.`}</p>
    <h2 {...{
      "id": "attack-plan",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#attack-plan",
        "aria-label": "attack plan permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Attack Plan`}</h2>
    <p>{`The idea is pretty straightforward to implement. This was what I started with:`}</p>
    <ul>
      <li parentName="ul">{`An input that allows users to upload their image`}</li>
      <li parentName="ul">{`Convert the image into base64`}</li>
      <li parentName="ul">{`Save the base64 in state`}</li>
      <li parentName="ul">{`Update the message component to use that image`}</li>
      <li parentName="ul">{`Make sure the image persists on new sessions/reloads`}</li>
    </ul>
    <h2 {...{
      "id": "implementation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#implementation",
        "aria-label": "implementation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementation`}</h2>
    <p>{`The web client is written in React/Typescript, so the example I will give here will use the same stack. This is worth noting because when handling files, you need to dance with typing to make typescript happy.`}</p>
    <p>{`We are also using `}<a parentName="p" {...{
        "href": ""
      }}>{`pullstate`}</a>{` as our main state manager, but in this article, I'll use React's useState hook to make it easier to understand.`}</p>
    <h3 {...{
      "id": "creating-the-component",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#creating-the-component",
        "aria-label": "creating the component permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating the Component`}</h3>
    <p>{`Since we will be using `}<inlineCode parentName="p">{`useState`}</inlineCode>{` we need to create a type for the state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type userSettingsState = {
  username: string
  avatar?: string
}
`}</code></pre>
    <p>{`We will set a default for `}<inlineCode parentName="p">{`username`}</inlineCode>{`, but not for `}<inlineCode parentName="p">{`avatar`}</inlineCode>{` since this will be the base64 string for the uploaded image. This means that `}<inlineCode parentName="p">{`avatar`}</inlineCode>{` will be either `}<inlineCode parentName="p">{`undefined | string`}</inlineCode>{`.`}</p>
    <p>{`Let's now build the `}<inlineCode parentName="p">{`UserSettings`}</inlineCode>{` component and use our `}<inlineCode parentName="p">{`useSettingsState`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type userSettingsState = {
  username: string
  avatar?: string
}

export const UserSettings = (): React.ReactElement => {
  const [userSettings, setUserSettings] = React.useState<userSettingsState>({
    username: "bob-the-builder",
  })

  const updateUsername = (e: React.FormEvent<HTMLInputElement>) => {}

  const updateAvatar = (e: React.FormEvent<HTMLInputElement>) => {}

  return (
    <div>
      <h2 className="flex align-items-center">User Settings</h2>
      <div className="flex flex-column padding-left">
        <div className="flex align-items-center">
          <p className="padding-right settings-text">Username</p>
          <input
            type="text"
            id="username"
            placeholder={username}
            defaultValue={username}
            onChange={updateUsername}
          />
        </div>
        <div className="flex align-items-center">
          <p className="padding-right settings-text">Avatar</p>
          <input type="file" onChange={updateAvatar} />
        </div>
      </div>
    </div>
  )
}
`}</code></pre>
    <h3 {...{
      "id": "creating-the-helper-functions-for-state",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#creating-the-helper-functions-for-state",
        "aria-label": "creating the helper functions for state permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating the helper functions for state`}</h3>
    <p>{`We now have two functions to fill, `}<inlineCode parentName="p">{`updateUsername`}</inlineCode>{` and `}<inlineCode parentName="p">{`updateAvatar`}</inlineCode>{`. All we need to do for the username is get the value from the input.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const updateUsername = (e: React.FormEvent<HTMLInputElement>) => {
  setUserSettings({
    username: e.currentTarget.value,
    // set avatar to whatever avatar is set
    avatar: avatar,
  })
}
`}</code></pre>
    <p>{`This one was pretty straightforward. The fun begins when we try to update the user's profile picture.`}</p>
    <p>{`Since we are using an input to upload the image, we can get the image details with `}<inlineCode parentName="p">{`e.target.files[0]`}</inlineCode>{` , but this doesn't work since typescript will throw an error at you saying that you are trying to access an attribute (files) that doesn't exist in the object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`Property 'files' does not exist on type 'EventTarget'.
`}</code></pre>
    <p>{`The trick here is setting the type of `}<inlineCode parentName="p">{`e.target`}</inlineCode>{` to `}<inlineCode parentName="p">{`HTMLInputElement`}</inlineCode>{`, by doing this, we can then access the files property.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const updateAvatar = (e: React.FormEvent<HTMLInputElement>) => {
  const target = e.target as HTMLInputElement
  const avatar = target.files[0]
}
`}</code></pre>
    <p>{`This will still give you an error since files is possibly `}<inlineCode parentName="p">{`null`}</inlineCode>{`. To fix that, we can check if `}<inlineCode parentName="p">{`target.files`}</inlineCode>{` exists and if it has any element inside.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const updateAvatar = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files.length) {
      const avatar = target.files[0];
}
`}</code></pre>
    <p>{`Now that we have the image file details, we can use `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/FileReader"
      }}>{`FileReader`}</a>{` to get the representation of the image as a base64 encoded string. Once we have the base64 encoded string, we can update our `}<inlineCode parentName="p">{`userSettings`}</inlineCode>{` state with this. Note that FileReader methods are async.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const updateAvatar = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files.length) {
      const avatar = target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(avatar);
      reader.onload = () => {
        const result = reader.result as string;
        if (result) {
          setUserSettings({
              username: username,
              avatar: result
          })
        }
      };
    }
`}</code></pre>
    <h3 {...{
      "id": "persisting-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#persisting-data",
        "aria-label": "persisting data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Persisting data`}</h3>
    <p>{`With opsdroid web client, we are persisting a lot of data through cookies, although we won't be able to store the base64 encoded string of the user profile picture because this string is too large. The option here is using `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage"
      }}>{`local storage`}</a>{` to persist the profile picture and fetch it when we reload/restart the client.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const updateAvatar = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files.length) {
      const avatar = target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(avatar);
      reader.onload = () => {
        const result = reader.result as string;
        if (result) {
          setUserSettings({
              username: username,
              avatar: result
          })
          localStorage.setItem("profile-picture", result)
        }
      };
    }
`}</code></pre>
    <p>{`We can then update our initial state to get the data from local storage if it exists.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const [userSettings, setUserSettings] = React.useState<userSettingsState>({
  username: localStorage.getItem("username") || "bob-the-builder",
  avatar: localStorage.getItem("profile-picture") || undefined,
})
`}</code></pre>
    <h2 {...{
      "id": "thats-all",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thats-all",
        "aria-label": "thats all permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`That's all`}</h2>
    <p>{`Now users can upload images, and we can save them to local storage and show them when the users come back. It's much better to have a backend so you can save and fetch the image. But, for this use case, local storage seems good enough.`}</p>
    <p>{`Here's the full code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type userSettingsState = {
  username: string;
  avatar?: string;
}

export const UserSettings = (): React.ReactElement => {

    const [ userSettings, setUserSettings] = React.useState<userSettingsState>({
        username: localStorage.getItem("username") || "bob-the-builder",
        avatar: localStorage.getItem("profile-picture") || undefined
    });

    const updateUsername = (e: React.FormEvent<HTMLInputElement>) => {
        setUserSettings({
            username: e.currentTarget.value,
            // set avatar to whatever avatar is set
            avatar: avatar
        })
    };

    const updateAvatar = (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        if (target.files && target.files.length) {
          const avatar = target.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(avatar);
          reader.onload = () => {
            const result = reader.result as string;
            if (result) {
              setUserSettings({
                  username: username,
                  avatar: result
              })
              localStorage.setItem("profile-picture", result)
            }
          };
        }

    return (
        <div>
            <h2 className="flex align-items-center">
              User Settings
            </h2>
            <div className="flex flex-column padding-left">
                <div className="flex align-items-center">
                    <p className="padding-right settings-text">Username</p>
                    <input
                      type="text"
                      id="username"
                      placeholder={username}
                      defaultValue={username}
                      onChange={updateUsername}
                    />
                </div>
                <div className="flex align-items-center">
                    <p className="padding-right settings-text">Avatar</p>
                    <input
                      type="file"
                      onChange={updateAvatar}
                    />
                </div>
            </div>
        </div>
    );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      